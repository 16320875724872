.neu-block {
  max-width: 306px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 64px;
  align-items: center;

  @include respond(tablet) {
    max-width: 1160px;
    gap: 24px;
    margin-bottom: 64px;
  }

  &__photo {
    margin: 0 8px;
    display: block;
    border-radius: 25px;
    aspect-ratio: 1/1;
    overflow: hidden;
    width: 290px;
    min-height: 290px;
    @include box-shadow;

    @include respond(tablet) {
      // max-width: 1160px;
      aspect-ratio: unset;
      border-radius: 15px;
      max-width: 100%;
      width: 100%;
    }
    picture {
      aspect-ratio: inherit;
      max-width: 100%;
      width: 100%;
    }

    &:hover {
      @include box-shadow-hover;
    }

    &:active {
      @include box-shadow-active;
    }

    img {
      position: relative;
      z-index: -1;
      width: 290px;
      min-height: 290px;
      object-fit: cover;
      object-position: center center;
      @include respond(tablet) {
        min-width: 100%;
        width: 100%;
      }
    }
  }
  &__paragraph,
  &__heading {
    margin-bottom: 0;
    text-align: center;
  }

  &__paragraph {
    font-family: $font-hedline;
    font-size: 16px;
    font-weight: 400;
    @include respond(tablet) {
      font-size: 18px;
    }

    strong {
      font-weight: 800;
    }
  }
}
