.about-brand {
  &--first {
    margin-top: 100px;
  }

  &__headline {
    text-transform: uppercase;
  }

  &__article {
    margin-top: 64px;
    margin-bottom: 160px;
  }

  &__article-content {
    padding-left: 10px;
    padding-right: 10px;
    @include respond(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 594px;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0;
      padding: 0;
      @include respond(mobile) {
        padding-left: 24px;
        padding-right: 24px;
      }

      strong,
      b {
        font-family: $font-hedline;
        font-weight: 900;
      }
    }

    p {
      font-weight: 400;
      font-size: 15px;
    }

    @include respond(tablet) {
    }

    img {
      border-radius: 25px;
      margin: 0;
      padding: 0;
      @include respond(tablet) {
        border-radius: 15px;
      }
    }
  }
}

.about-brand-hero {
  min-height: 510px;
  position: relative;
  overflow: hidden;

  picture img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    min-width: fit-content;
    width: 100%;
    z-index: -1;
  }

  &--photo-align-center {
    picture img {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include respond(tablet) {
    min-height: 540px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    text-align: center;
    margin-bottom: 64px;
    @include respond(tablet) {
      margin-bottom: 0;
    }
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;

    br {
      display: block;
      @include respond(desktop) {
        display: none;
      }
    }
  }
}
