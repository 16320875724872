.history {
  padding: 180px 0 0;
  background-image: url("./static/img/history/history-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  @include respond(tablet) {
    background-image: url("./static/img/history/history-bg.jpg");
    background-position: top center;

    // background-size: 100%;
  }
  @include respond(desktop) {
    background-size: cover;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 109px;

    @include respond(desktop) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__heading {
    order: 0;
    color: #fff;
    text-shadow: 0px 0px 6.956px rgba(0, 0, 0, 0.87);
    text-align: center;
    margin-bottom: 100px;
    @include respond(desktop) {
      order: 1;
      text-align: left;
      margin-bottom: auto;
    }
  }

  &__logo {
    order: 1;
    max-width: 166px;
    @include respond(desktop) {
      order: 0;
    }
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
  }

  &__tabs {
    background: linear-gradient(
      87deg,
      rgba(1, 41, 77, 0.8) 0.5%,
      rgba(5, 97, 177, 0.8) 46.04%,
      rgba(0, 28, 52, 0.8) 99.5%
    );
    padding: 30px 0 48px;
    @include respond(desktop) {
      padding-bottom: 20px;
    }
  }

  &__year {
    color: #fff;
    font-size: 61px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 6px;
    @include respond(desktop) {
      font-size: 36px;
    }
  }
  &__description {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.28em; /* 128% */
    @include respond(desktop) {
      font-size: 14px;
      margin-bottom: 2px;
    }
  }
  &__icon {
    width: 156px;
    @include respond(desktop) {
      width: 92px;
    }
  }
}

.tab-nav-bar {
  margin-bottom: 32px;
  @include respond(desktop) {
    margin-bottom: 64px;
  }
}

.tab-menu {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
  @include respond(desktop) {
    gap: 80px;
  }
}

.tab-btn {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-shadow: 0px 0px 6.956px rgba(0, 0, 0, 0.87);
  padding: 0 5px;
  @include respond(desktop) {
    font-size: 15px;
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: transparent;
    margin-top: 30px;
    transition: all 0.3s ease;
  }

  &.active,
  &:hover {
    cursor: pointer;
    color: #fff;
    font-weight: 800;
    padding: 0 3px;

    &::after {
      background-color: #fff;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    }
  }
}

/* ===== Tab content ===== */
.tab-content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.tab {
  opacity: 0;

  transform: translateX(25px);
  content-visibility: hidden;
}

.tab.active {
  transform: translateX(0);
  content-visibility: visible;
  opacity: 1;
  transition:
    opacity 1s ease,
    transform 1s ease;
}

.history-slider {
  > div {
    max-width: fit-content;
  }
}

.history-slide {
  width: 256px;
  height: 395px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 26px;

  @include respond(desktop) {
    height: 233px;
    width: 156px;
    padding: 0px 14px 5px;
  }

  &:not(:first-child) {
    border-left: 1.695px solid #fff;
  }

  &:first-child {
    padding-left: 50px;
    @include respond(desktop) {
      padding-left: 16px;
    }
  }
}

.history-slider .swiper-wrapper {
  padding-bottom: 56px;
}

.history-slider .swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: 70px;
  background: #dbdbdb;

  .swiper-scrollbar-drag {
    background: linear-gradient(270deg, #ff9500 0%, #e00 100%);
  }
}

.history-swiper-nav {
  display: flex;
  justify-content: center;
  gap: 12px;
  top: 12px;
  position: relative;
}

.logo-winston-70 {
  padding: 40px 20px 50px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
}
