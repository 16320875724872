.navbar {
  background: rgba($color: #000000, $alpha: 0.5);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 100%;
  transition: all 0.2s ease-in-out;

  &--blue-universal {
    background: $blue-universal;
    backdrop-filter: none;
  }

  &-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    --nav-height: 80px;
    height: var(--nav-height);
    transition: height 0.2s ease-in-out;

    &.sticky {
      --nav-height: 64px;

      @include respond(desktop) {
      }
    }

    // &.sticky {
    //   .navbar--blue-universal {
    //     background: rgba($color: #000000, $alpha: 0.5);
    //     -webkit-backdrop-filter: blur(16px);
    //     backdrop-filter: blur(16px);
    //   }
    // }

    &::after {
      position: absolute;
      content: "";
      height: 48px;
      width: 100%;
      z-index: -1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  &-toggler {
    background: transparent;
    border: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    // margin: 16px;

    .close,
    .close::before,
    .close::after {
      background: white;
      content: "";
      position: absolute;
      width: 24px;
      height: 3px;
      left: 0;
      margin-top: 0;
      transform-origin: center;
      transition: 0.2s ease-in-out;
    }

    .close::before {
      margin-top: -10px;
    }

    .close::after {
      margin-top: 10px;
    }

    &.active .close {
      background: transparent;
    }

    &.active .close::before {
      margin-top: 0;
      transform: rotate(45deg);
    }

    &.active .close::after {
      margin-top: 0;
      transform: rotate(-45deg);
    }

    &.active {
      transform: rotateY(180deg);
      transition: 0.2s ease-in-out;
    }

    .back {
      &::after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IkFycm93IDIxIiBkPSJNMC4yOTI4OTMgNy4yOTI4OUMtMC4wOTc2MzE1IDcuNjgzNDIgLTAuMDk3NjMxNSA4LjMxNjU4IDAuMjkyODkzIDguNzA3MTFMNi42NTY4NSAxNS4wNzExQzcuMDQ3MzggMTUuNDYxNiA3LjY4MDU0IDE1LjQ2MTYgOC4wNzEwNyAxNS4wNzExQzguNDYxNTkgMTQuNjgwNSA4LjQ2MTU5IDE0LjA0NzQgOC4wNzEwNyAxMy42NTY5TDIuNDE0MjEgOEw4LjA3MTA3IDIuMzQzMTVDOC40NjE1OSAxLjk1MjYyIDguNDYxNTkgMS4zMTk0NiA4LjA3MTA3IDAuOTI4OTMyQzcuNjgwNTQgMC41Mzg0MDggNy4wNDczOCAwLjUzODQwOCA2LjY1Njg1IDAuOTI4OTMyTDAuMjkyODkzIDcuMjkyODlaTTE2IDdMMSA3VjlMMTYgOVY3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
        position: absolute;
        width: 16px;
        height: 16px;
        top: 8px;
        left: 55px;
        transform: rotate(180deg);
        transition: left 0.2s ease-in-out;
        opacity: 0;
      }
    }

    &.show-back {
      .close {
        display: none;
      }

      .back {
        width: 24px;
        height: 24px;
        display: block;
        margin-left: -4px;

        &::after {
          left: 5px;
          opacity: 1;
        }
      }
    }

    @include respond(desktop) {
      display: none;
    }
  }

  &-brand {
    @include respondMax(tablet) {
      // max-height: 35px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
  }

  &-logo {
    max-width: 67px;
    height: auto;
    margin-top: -10px;
    transition: max-width 0.2s ease-in-out;

    // @include respond(desktop) {
    //   max-width: 83px;
    // }
  }

  &-profile {
    // @include respondMax(tablet) {
    margin-left: auto;
    // }
  }

  &-points {
    margin-left: calc(18px - $default-gap);
    @include respond(desktop) {
      margin-left: calc(55px - $default-gap);
    }
  }
}

.navbar {
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    gap: $default-gap;
    position: relative;
  }
}

.nav-sub {
  padding-left: 24px;
  list-style-type: none;
}

.navbar {
  &-collapse {
    position: absolute;

    @include respondMax(tablet) {
      height: calc(100vh);
      width: 100svw;
      left: calc(100svw * -1);
      z-index: -1;
      background: url("static/img/menu-bg.jpg") $blue-universal;
      background-size: cover;
      background-repeat: no-repeat;
      transition:
        // opacity 0.08s,
        left 0.08s ease-in-out;
      opacity: 0;
      top: 0;
    }

    @include respond(desktop) {
      // position: relative;
      left: 50%;
      transform: translateX(-50%);
      // margin: 0 auto;
      height: 100%;
      // opacity: 1;
      > div {
        height: 100%;
      }
    }

    @include respondMax(tablet) {
      &.show {
        left: 0;
        opacity: 1;
      }
    }

    .nav {
      padding: 0;

      @include respondMax(tablet) {
        margin-top: calc(80px + 48px);
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $default-gap;
        margin-left: 32px;
      }
      @include respond(desktop) {
        display: inline-flex;
        gap: 16px;
        justify-content: center;
        height: 100%;
      }
      @include respond(big-desktop) {
        display: inline-flex;
        gap: 48px;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.navbar {
  .nav-item {
    list-style: none;
    padding: 0;
    margin: 0;
    @include respond(desktop) {
      text-align: center;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 10px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: #ffffff;
        width: 0%;
        height: 3px;
        transition: width 0.15s ease-in-out;
      }
    }
    &.active,
    &:focus,
    &:active,
    &:hover {
      // font-weight: 800;
      @include respond(desktop) {
        &::before {
          width: 100%;
        }
      }

      .nav-dropdown {
        @include respond(desktop) {
          &::before {
            content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEzIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJWZWN0b3IgMjciIGQ9Ik0xLjI1IDAuNUw2Ljc1IDUuNUwxMS43NSAwLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K);
            right: 0;
            transform: rotate(0deg) translateY(-15%);
          }
        }
      }
    }

    &.active,
    .active a {
      font-weight: 800;
    }

    > .active {
      @include respond(desktop) {
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          background: #ffffff;
          width: 100%;
          height: 3px;
        }
      }

      a {
        font-weight: 800;
        border: 0;
      }
      font-weight: 800;
      border: 0;
    }

    > a {
      font-size: 25px;
      color: white;
      text-decoration: none;
      font-family: $font-hedline;
      line-height: 150%;
      text-wrap: nowrap;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      transition: padding-top 0.2s ease-in-out;

      @include respond(desktop) {
        padding-top: 24px;
        font-size: 15px;

        &::after {
          content: attr(data-text);
          content: attr(data-text) / "";
          height: 0;
          visibility: hidden;
          overflow: hidden;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
          display: inline-block;
          font-weight: 800;
          text-wrap: nowrap;
        }
      }
    }

    .nav-dropdown {
      @include respond(desktop) {
        padding-right: 15px;

        &::before {
          content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEzIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjI1IDAuNUw2Ljc1IDUuNUwxMS43NSAwLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
          width: 17px;
          height: 18px;
          position: absolute;
          right: 10px;
          transform-origin: center;
          transform: rotate(0deg) translateY(-15%);
          transition: all 0.2s;
        }
      }
    }

    .nav-level {
      &-container {
        text-align: left;
        // opacity: 0;
        transition: none;
        display: none;

        @include respond(desktop) {
          animation: slide-down 0.2s ease-in;
          position: absolute;
          background: #ffffff;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.18);
          z-index: -20;
          border-radius: 15px;
          padding: 48px 0 48px 48px;
          // opacity: 0;
          z-index: -50;
          // transition:
          //   top 0.2s ease-in-out,
          //   opacity 0.2s ease-in-out,
          //   display 0.2s ease-in-out;
          top: calc(var(--nav-height) - 4px);
          left: 50%;
          transform: translateX(-50%);
          width: 294px;
        }

        h5 {
          font-family: $font-hedline;
          font-weight: 800;
          font-size: 25px;
          padding-bottom: 8px;
          padding-top: 8px;
          margin-bottom: 0;

          @include respond(desktop) {
            font-size: 16px;
          }
          @include respondMobileHeight() {
            font-size: 18px;
          }
        }
      }
    }

    &.nav-expanded {
      .nav-level {
        &-container {
          @include respond(desktop) {
            display: block;
            animation: slide-down 0.2s ease-in;
            // z-index: 6;
            // opacity: 1;
            // top: calc(var(--nav-height) - 4px);
          }
        }
      }

      @keyframes slide-down {
        0% {
          opacity: 0;
          top: calc(var(--nav-height) - 25px);
        }

        100% {
          opacity: 1;
          top: calc(var(--nav-height) - 4px);
        }
      }

      .nav-dropdown {
        &::before {
          right: 0;
        }
      }

      .nav-dropdown {
        @include respond(desktop) {
          font-weight: 800;
          &::before {
            content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEzIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJWZWN0b3IgMjciIGQ9Ik0xLjI1IDAuNUw2Ljc1IDUuNUwxMS43NSAwLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K);
            // width: 17px;
            // height: 18px;
            // position: absolute;
            // right: -15px;
            // transform-origin: center;
            transform: rotate(180deg) translateY(-55%);
            transition: transform 0.2s;
          }
        }
      }
    }
  }
}

.navbar {
  .nav-sub-item {
    font-weight: normal;
    // margin-bottom: 8px;
    min-height: min-content;
    a {
      display: inline-block;
      font-size: 25px;
      font-family: $font-hedline;
      font-weight: 400;
      color: #fff;
      text-decoration: none;
      height: 40px;

      @include respond(desktop) {
        height: 31px;
      }
      cursor: pointer;
      @include respond(desktop) {
        font-size: 16px;
        color: $blue-universal;
      }
      @include respondMobileHeight() {
        font-size: 18px;
        height: 31px;
      }
      &:hover {
        font-weight: 800;
      }
    }

    .inner a {
      @include respondMax(tablet) {
        font-size: 20px;
      }
      @include respondMobileHeight() {
        font-size: 16px;
      }
    }

    &-foldable.expanded > a {
      &::before {
        transform: translatey(-50%) rotate(-90deg);
        opacity: 0;
      }
      &::after {
        transform: translatey(-50%) rotate(0);
      }
    }

    &-foldable > a {
      position: relative;
      display: inline-block;
      padding-right: 36px;

      height: 40px;

      @include respond(desktop) {
        height: 31px;
      }
      @include respondMobileHeight() {
        height: 31px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        background-color: #fff;
        position: absolute;
        top: 40%;
        right: 0;
        transition: transform 0.2s ease-in-out;
        width: 12px;
        height: 1px;
        @include respond(desktop) {
          background-color: $blue-universal;
        }
      }

      &::before {
        transform: translatey(-50%);
      }

      &::after {
        transform: translatey(-50%) rotate(90deg);
      }

      &:hover {
        font-weight: 800;

        &::before,
        &::after {
          height: 2px;
        }
      }
    }

    &-foldable {
      &.expanded .inner {
        grid-template-rows: 1fr;
        // margin-top: $margin-size;
      }

      .inner {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.2s ease-out;
      }

      .nav-sub {
        overflow: hidden;
      }
    }
  }
}

.navbar {
  .navbar-collapse.show .nav-item {
    .nav-level-container {
      @include respondMax(tablet) {
        position: absolute;
        display: block;
        height: calc(100vh);
        width: 100svw;
        left: calc(100svw * -1);
        z-index: 1;
        background: url("static/img/menu-bg.jpg") $blue-universal;
        background-size: cover;
        background-repeat: no-repeat;
        transition:
        // opacity 0.08s,
          left 0.2s ease-in-out;
        top: 0;
        padding: 128px 0 128px 32px;

        h5 {
          color: #ffffff;
          background: none;
          background-clip: unset;
          -webkit-text-fill-color: #ffffff;
        }

        .nav-sub-item a {
          color: #ffffff;
        }
      }
    }

    &.nav-expanded {
      .nav-level-container {
        @include respondMax(tablet) {
          left: 0;
        }
      }
    }
  }
}

.sticky {
  .navbar-logo {
    // @include respond(desktop) {
    max-width: 54px;
    // }
    // @include respond(desktop) {
    //   max-width: 54px;
    // }
  }

  .navbar-collapse .nav-item > a {
    @include respond(desktop) {
      padding-top: 10px;
    }
  }
}
