h1,
h2,
h3,
h4,
h5 {
  font-family: $font-hedline;
  font-weight: 900;
  margin-bottom: calc($margin-size * 3);
  line-height: normal;
  // width: max-content;
}

h3,
h4,
h5 {
  font-weight: 800;
}

h1 {
  font-size: 35px;
  @include respond(desktop) {
    font-size: 45px;
  }
}

h2 {
  font-size: 25px;
  @include respond(desktop) {
    font-size: 35px;
  }
}

h3 {
  font-size: 18px;
  @include respond(desktop) {
    font-size: 20px;
  }
}

h4 {
  font-size: 16px;
  @include respond(desktop) {
    font-size: 18px;
  }
}

h5 {
  font-size: 15px;
}

.text-gradient-blue {
  // width: max-content;
  background: $gradient-blue;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-red {
  // width: max-content;
  background: $gradient-red;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-claret {
  // width: max-content;
  background: $gradient-claret;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
