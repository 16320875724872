@import "vars";
@import "init";
@import "functions";
@import "_mixins/box-shadow";

@import "base";

@import "media";
@import "utils";
@import "fonts";
@import "shared";
@import "headings";
@import "paragraph";
@import "links";
@import "content";
//components
@import "components/navbar";
@import "components/buttons";
@import "components/hero";
@import "components/forms";
@import "components/neu-block";
@import "components/slider";
@import "components/navback";
@import "components/product-slider";
@import "components/modal";
@import "components/my-points";
@import "components/my-points-add";
@import "components/video-play";
@import "components/voucher";
@import "components/info-box";
@import "components/game";

@import "login-page";
@import "registration-page";
@import "section";
@import "about-brand";
@import "news";
@import "profile";
@import "festivals";
@import "contact";
@import "my_account";
@import "add-code";
@import "competition";
@import "vouchers";
@import "competition-2";
@import "history";
@import "footer";
@import "public-page";

body {
    font-family: $font-primary;
}
