.game {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @include respond(tablet) {
      gap: 16px;
    }

    @include respond(desktop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 64px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__wrap--2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @include respond(tablet) {
      gap: 16px;
      max-width: 570px;
      margin: 0 auto;
    }

    @include respond(desktop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 64px;
      max-width: unset;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__nav {
    display: flex;
    align-self: flex-start;
    margin-bottom: 10px;
  }

  &__item {
    flex-basis: 50%;
    margin: 0 -16px;

    @include respond(desktop) {
      margin: unset;
      img {
        border-radius: 15px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #fff;

    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    // padding-top: 32px;
    @include respond(desktop) {
      max-width: 500px;

      padding-top: 0;
      padding-left: 32px;
      flex-basis: 50%;
    }
  }

  &__heading {
    text-transform: uppercase;
  }

  &__desc {
    margin-bottom: 32px;
    line-height: 22px;
    // text-align: center;
    text-align: left;

    @include respond(desktop) {
      width: 100%;
    }

    strong {
      font-family: $font-hedline;
      font-weight: 700;
    }
  }
}

.game-section {
  min-height: 1090px;
  // padding: 0 16px;
  padding-top: 80px;
  padding-bottom: 130px;
  background-image: url("./static/img/game-bg-m.jpg");
  background-size: cover;
  @include respond(desktop) {
    padding-top: 96px;
    min-height: 900px;
    background-image: url("./static/img/game-bg.jpg");
  }

  &--2 {
    background-image: url("./static/img/game-bg-m-2.jpg");
    @include respond(desktop) {
      background-image: url("./static/img/game-bg-2.jpg");
    }
  }
}

.game-inst-desktop {
  display: block;
  @media (pointer: coarse) {
    display: none;
  }
}

.game-inst-touch {
  display: none;
  @media (pointer: coarse) {
    display: block;
  }
}
