.competition--first {
  margin-top: 100px;
}

.competition {
  .neu-block__paragraph {
    max-width: 778px;

    br {
      display: none;
    }
    @include respond(desktop) {
      br {
        display: block;
      }
    }
  }

  &__article {
    margin-bottom: 0;
    padding-bottom: 160px;
    margin-top: 0;
    padding-top: 80px;
    color: #fff;

    background: url("./static/img/konkurs-1-bg.jpg") no-repeat;
    background-size: cover;

    @include respond(tablet) {
      background: url("./static/img/konkurs-1-bg-tablet.jpg") no-repeat;
      background-position: center top;
      // background-size: cover;
    }

    @include respond(desktop) {
      background: url("./static/img/konkurs-1-bg-desktop.jpg") no-repeat;
      background-position: center top;

      // background-size: cover;
    }

    @media only screen and (min-width: 1440px) {
      background: url("./static/img/konkurs-1-bg-bigdesktop.jpg") no-repeat;
      background-position: center top;
    }
    @media only screen and (min-width: 1920px) {
      background-size: cover;
    }
  }

  &__article-content {
    align-items: center;
    max-width: 871px;
    margin-bottom: 64px;

    h2 {
      max-width: 871px;
      margin-bottom: 0;
    }
    h3,
    p {
      max-width: 700px;
      text-align: center;
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 32px;

    p {
      font-weight: 400;
      font-size: 15px;
    }

    img {
      border-radius: 25px;
      margin: 0;
      padding: 0;
      @include respond(tablet) {
        border-radius: 15px;
      }
    }
  }

  &--on-color {
    margin-bottom: -65px;
  }
}

.competition {
  &__steps {
    margin-bottom: 55px;

    h2 {
      margin-bottom: 64px;
    }
  }

  &__steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @include respond(desktop) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__steps-item {
    display: flex;
    padding-bottom: 15px;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    max-width: 340px;
    @include respond(big-desktop) {
      min-width: 340px;
    }
  }

  &__steps-box {
    width: 232px;
    height: 232px;
    border-radius: 23px;
    background: #f3f3f3;
    font-family: $font-hedline;
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
  }

  &__steps-description {
    text-align: center;
    margin-bottom: 0;
    min-height: 75px;
    font-size: 20px;
    font-weight: 500;

    a {
      font-family: $font-hedline;
      font-weight: 900;
      color: white;
      text-decoration: none;
    }
  }
}

.competition {
  &__award {
    background: rgba(0, 0, 0, 0.2);
    padding: 64px 0 60px;
    @include respond(desktop) {
      padding: 64px 0 94px;
    }

    br {
      display: none;
      @include respond(tablet) {
        display: block;
      }
    }
  }

  &__award-item {
    font-size: 54px;
    font-family: $font-hedline;
    font-weight: 800;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    line-height: 1em;

    @include respond(desktop) {
      font-size: 90px;
      flex-direction: row;
      gap: 28px;
      align-items: center;
      margin-top: 64px;
    }
    span {
      margin-top: -20px;
      line-height: 1em;
      @include respond(desktop) {
        margin-top: -40px;
      }
    }

    // img {
    //   @include respond(desktop) {
    //     width: 242px;
    //   }
    // }
  }
}

.competition {
  &__answer {
    padding: 64px 0 0;
    max-width: 621px;
    margin: 0 auto;

    h2 {
      margin-bottom: 24px;
      @include respond(tablet) {
        margin-bottom: 32px;
      }
    }
  }

  &__form {
    padding: 0 calc(40px - 16px) 67px;

    @include respond(tablet) {
      padding: 0 34px 67px;
    }

    label.radio-label a {
      color: #fff;
    }

    .radio-container input {
      margin-top: 15px;
    }

    .required-fields-text {
      text-align: right;
      margin-bottom: 40px;
    }

    .field-container--pl {
      @include respond(tablet) {
        padding-left: 20px;
      }
    }
  }

  &__rules {
    display: flex;
    flex-direction: column;

    @include respond(tablet) {
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    p {
      font-size: 18px;
    }
  }

  &__rules-link {
    font-family: $font-hedline;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #fff;
    text-decoration: none;
  }
}

.competition-hero {
  min-height: 510px;
  position: relative;
  overflow: hidden;

  picture img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    min-width: fit-content;
    width: 100%;
    z-index: -1;
  }

  &--photo-align-center {
    picture img {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include respond(tablet) {
    min-height: 540px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    max-width: 252px;
    @include respond(tablet) {
      max-width: 352px;
    }
  }

  &__content {
    text-align: center;
    // margin-bottom: 64px;
    // @include respond(tablet) {
    //   margin-bottom: 0;
    // }
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 60px;
    margin-bottom: 0;

    br {
      display: none;
      @include respond(tablet) {
        display: block;
      }
    }
  }
}
