.hero {
  min-height: 554px;
  position: relative;
  overflow: hidden;

  picture img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    min-width: fit-content;
    width: 100%;
    z-index: -1;
  }
  @include respondMax(tablet) {
    &--black-pink {
      picture img {
        object-position: left bottom;
        max-width: none;
        height: 100%;
      }
    }
  }
  &--photo-align-center {
    picture img {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include respond(tablet) {
    min-height: 800px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    text-align: center;
    max-width: 672px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin-top: 80px;
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;

    br {
      display: block;
      @include respond(tablet) {
        display: none;
      }
    }
  }

  &__subheading {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    @include respond(tablet) {
      font-size: 20px;
    }
  }

  &__logo {
    width: 166px;
    margin: 0 auto;
  }

  &__scroll-position {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include respond(tablet) {
      bottom: 115px;
    }
  }

  &__scroll-position--desktop {
    display: none;

    @include respond(tablet) {
      display: block;
      bottom: 80px;
    }
  }
}
