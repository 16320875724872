.login-page {
  display: flex;
  flex-direction: column;

  @include respond(desktop) {
    flex-direction: row;
    min-height: calc(100vh - 50px);
  }

  &__logo {
    margin: 0 auto 36px;

    img {
      margin: 0 auto;
      max-width: 57px;
    }
  }

  h2 {
    font-size: 25px;
  }
  h4 {
    font-size: 16px;
    max-width: 310px;
  }
  form {
    width: 100%;
    @include respond(tablet) {
      width: auto;
    }
  }

  &__bottom-links {
    a {
      font-size: 12px;
    }
  }
}

.login-around {
  display: flex;
  justify-content: space-around;
}
.login-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.login-info {
  max-width: 450px;
  br {
    display: none;
    @include respond(tablet) {
      display: block;
    }
  }
}

.login-container {
  padding-top: 34px;
  padding-bottom: 34px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktop-visual {
  display: none;

  @include respond(desktop) {
    display: block;
    flex-basis: 50%;

    background-image: url("static/img/bg-loging.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
  }
}
