.video-play {
  &__container {
    @include respondMax(mobile) {
      position: fixed;
      top: 50%;
      transform: scale(0);
      transition: all 0.3s;
    }
  }
  position: relative;

  border-radius: 25px;

  @include respond(tablet) {
    border-radius: 15px;
    max-width: 1090px;
  }
  video,
  img {
    border-radius: 25px;
    transition: all 0.3s;

    @include respond(tablet) {
      border-radius: 15px;
    }
  }
  picture {
    @include respondMax(mobile) {
      position: relative;
      display: block;
    }

    &::after {
      @include respondMax(mobile) {
        width: 90px;
        height: 90px;
        content: "";
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAzIiBoZWlnaHQ9IjIyMyIgdmlld0JveD0iMCAwIDIwMyAyMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJpa29ua2kgMV9QTEFZIDEiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTAyLjI3OCAxOTMuMzdDNTUuODE3OCAxOTMuMzcgMTguMDA3OCAxNTUuNTcgMTguMDA3OCAxMDkuMUMxOC4wMDc4IDYyLjYzMDEgNTUuODA3OCAyNC44MzAxIDEwMi4yNzggMjQuODMwMUMxNDguNzQ4IDI0LjgzMDEgMTg2LjU0OCA2Mi42MzAxIDE4Ni41NDggMTA5LjFDMTg2LjU0OCAxNTUuNTcgMTQ4Ljc0OCAxOTMuMzcgMTAyLjI3OCAxOTMuMzdaTTEwMi4yNzggMzQuODMwMUM2MS4zMjc4IDM0LjgzMDEgMjguMDA3OCA2OC4xNTAxIDI4LjAwNzggMTA5LjFDMjguMDA3OCAxNTAuMDUgNjEuMzI3OCAxODMuMzcgMTAyLjI3OCAxODMuMzdDMTQzLjIyOCAxODMuMzcgMTc2LjU0OCAxNTAuMDUgMTc2LjU0OCAxMDkuMUMxNzYuNTQ4IDY4LjE1MDEgMTQzLjIyOCAzNC44MzAxIDEwMi4yNzggMzQuODMwMVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGlkPSJWZWN0b3JfMiIgZD0iTTE0OC4wNTcgMTAwLjk1TDg3LjkwNjkgNjYuMjIwMUM4MS42MzY5IDYyLjYwMDEgNzMuNzk2OSA2Ny4xMzAxIDczLjc5NjkgNzQuMzcwMVYxNDMuODNDNzMuNzk2OSAxNTEuMDcgODEuNjM2OSAxNTUuNiA4Ny45MDY5IDE1MS45OEwxNDguMDU3IDExNy4yNUMxNTQuMzI3IDExMy42MyAxNTQuMzI3IDEwNC41OCAxNDguMDU3IDEwMC45NlYxMDAuOTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=);
        position: absolute;
        background-size: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    display: none;
  }

  &__controll {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAzIiBoZWlnaHQ9IjIyMyIgdmlld0JveD0iMCAwIDIwMyAyMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJpa29ua2kgMV9QTEFZIDEiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTAyLjI3OCAxOTMuMzdDNTUuODE3OCAxOTMuMzcgMTguMDA3OCAxNTUuNTcgMTguMDA3OCAxMDkuMUMxOC4wMDc4IDYyLjYzMDEgNTUuODA3OCAyNC44MzAxIDEwMi4yNzggMjQuODMwMUMxNDguNzQ4IDI0LjgzMDEgMTg2LjU0OCA2Mi42MzAxIDE4Ni41NDggMTA5LjFDMTg2LjU0OCAxNTUuNTcgMTQ4Ljc0OCAxOTMuMzcgMTAyLjI3OCAxOTMuMzdaTTEwMi4yNzggMzQuODMwMUM2MS4zMjc4IDM0LjgzMDEgMjguMDA3OCA2OC4xNTAxIDI4LjAwNzggMTA5LjFDMjguMDA3OCAxNTAuMDUgNjEuMzI3OCAxODMuMzcgMTAyLjI3OCAxODMuMzdDMTQzLjIyOCAxODMuMzcgMTc2LjU0OCAxNTAuMDUgMTc2LjU0OCAxMDkuMUMxNzYuNTQ4IDY4LjE1MDEgMTQzLjIyOCAzNC44MzAxIDEwMi4yNzggMzQuODMwMVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGlkPSJWZWN0b3JfMiIgZD0iTTE0OC4wNTcgMTAwLjk1TDg3LjkwNjkgNjYuMjIwMUM4MS42MzY5IDYyLjYwMDEgNzMuNzk2OSA2Ny4xMzAxIDczLjc5NjkgNzQuMzcwMVYxNDMuODNDNzMuNzk2OSAxNTEuMDcgODEuNjM2OSAxNTUuNiA4Ny45MDY5IDE1MS45OEwxNDguMDU3IDExNy4yNUMxNTQuMzI3IDExMy42MyAxNTQuMzI3IDEwNC41OCAxNDguMDU3IDEwMC45NlYxMDAuOTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include respondMax(mobile) {
      width: 90px;
      height: 90px;
    }
    // transition: opacity 0.3s;
  }

  &.plaing {
    .video-play__container {
      .video-play__controll {
        opacity: 0;
        // z-index: -1;
      }
      transform: scale(1);

      @include respondMax(mobile) {
        border-radius: 0;
        position: fixed;
        top: 60px;
        width: 100%;
        left: 0;
        border-radius: 0;
      }

      &:hover {
        .video-play__controll {
          @include respond(tablet) {
            opacity: 1;
            content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAzIiBoZWlnaHQ9IjIyMyIgdmlld0JveD0iMCAwIDIwMyAyMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJpa29ua2kgMV9QQVVTRSAxIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTEwMS4wNjcgMTkzLjA0QzU0LjYwNjkgMTkzLjA0IDE2Ljc5NjkgMTU1LjI0IDE2Ljc5NjkgMTA4Ljc3QzE2Ljc5NjkgNjIuMyA1NC42MDY5IDI0LjUgMTAxLjA2NyAyNC41QzE0Ny41MjcgMjQuNSAxODUuMzM3IDYyLjMgMTg1LjMzNyAxMDguNzdDMTg1LjMzNyAxNTUuMjQgMTQ3LjUzNyAxOTMuMDQgMTAxLjA2NyAxOTMuMDRaTTEwMS4wNjcgMzQuNUM2MC4xMTY5IDM0LjUgMjYuNzk2OSA2Ny44MiAyNi43OTY5IDEwOC43N0MyNi43OTY5IDE0OS43MiA2MC4xMTY5IDE4My4wNCAxMDEuMDY3IDE4My4wNEMxNDIuMDE3IDE4My4wNCAxNzUuMzM3IDE0OS43MiAxNzUuMzM3IDEwOC43N0MxNzUuMzM3IDY3LjgyIDE0Mi4wMTcgMzQuNSAxMDEuMDY3IDM0LjVaIiBmaWxsPSJ3aGl0ZSIvPgo8ZyBpZD0iR3JvdXAiPgo8cGF0aCBpZD0iVmVjdG9yXzIiIGQ9Ik04Ny4yODM3IDY1LjE2OTlINjkuODYzOEM2NS4xNTgzIDY1LjE2OTkgNjEuMzQzOCA2OC45ODQ1IDYxLjM0MzggNzMuNjg5OVYxNDMuODRDNjEuMzQzOCAxNDguNTQ1IDY1LjE1ODMgMTUyLjM2IDY5Ljg2MzggMTUyLjM2SDg3LjI4MzdDOTEuOTg5MiAxNTIuMzYgOTUuODAzOCAxNDguNTQ1IDk1LjgwMzggMTQzLjg0VjczLjY4OTlDOTUuODAzOCA2OC45ODQ1IDkxLjk4OTIgNjUuMTY5OSA4Ny4yODM3IDY1LjE2OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBpZD0iVmVjdG9yXzMiIGQ9Ik0xMzIuMjg0IDY1LjE2OTlIMTE0Ljg2NEMxMTAuMTU4IDY1LjE2OTkgMTA2LjM0NCA2OC45ODQ1IDEwNi4zNDQgNzMuNjg5OVYxNDMuODRDMTA2LjM0NCAxNDguNTQ1IDExMC4xNTggMTUyLjM2IDExNC44NjQgMTUyLjM2SDEzMi4yODRDMTM2Ljk4OSAxNTIuMzYgMTQwLjgwNCAxNDguNTQ1IDE0MC44MDQgMTQzLjg0VjczLjY4OTlDMTQwLjgwNCA2OC45ODQ1IDEzNi45ODkgNjUuMTY5OSAxMzIuMjg0IDY1LjE2OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjwvZz4KPC9zdmc+Cg==);
          }
        }
      }
    }

    @include respondMax(mobile) {
      video {
        border-radius: 0;
      }
      picture {
        display: block;
      }
    }
  }
}
