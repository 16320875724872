.profile {
  margin-bottom: 80px;
  h1 {
    text-transform: uppercase;
  }

  &__paragraph {
    max-width: 426px;
  }

  &__container {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__block {
    position: inherit;
    border-radius: 15px;
    border: 1px solid rgba(0, 50, 255, 0.4);
    padding: 24px 32px 24px;
    text-align: left;
    margin-bottom: 32px;
    width: 100%;

    &--small-paddings {
      padding: 24px 16px 24px;
    }

    @include respond(desktop) {
      padding-left: 91px;
      padding-right: 91px;
    }

    &:last-child {
      margin-bottom: 40px;
    }

    @include respond(tablet) {
      width: 100%;
      max-width: 872px;
    }

    p {
      font-size: 18px;
      margin-bottom: 40px;
    }

    .radio-container {
      input {
        margin-top: inherit;
      }
    }
  }
}

.form-container {
  &__modal {
    padding: 0 23px;

    .password-rules {
      @include respond(tablet) {
        max-width: 320px;
      }
    }
  }
}
// .logout-sticky {
//   position: sticky;
//   bottom: 0;
// }
