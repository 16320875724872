.link {
  font-family: $font-hedline;
  margin-bottom: calc($margin-size * 3);
  color: #000;
  letter-spacing: 1.5px;
  text-decoration: none;
  border-bottom: 1px solid #000;
  font-weight: 400;
}

.cta {
  font-family: $font-hedline;
  font-weight: 800;
  color: $blue-universal;
  font-size: 15px;
  text-decoration: none;
  letter-spacing: 1.5px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid $blue-universal;
  }

  &--red {
    color: $red-universal;
    &:hover {
      border-color: $red-universal;
    }
  }
}

.cta-cancel {
  font-family: $font-hedline;
  font-weight: 800;
  font-size: 15px;
  text-decoration: none;
  letter-spacing: 1.5px;
  display: block;
  border: none;
  cursor: pointer;
}

.external-link {
  color: #0032ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
}
