// media query setup
$mobile: 576px;
$tablet: 768px;
$desktop: 992px;
$big-desktop: 1200px;

$mobile-small: 575.98px;
$mobile-max: 767.98px;
$tablet-max: 991.98px;
$desktop-max: 1338px;

// colors
$blue-universal: #0032ff;
$red-universal: #ee0000;

$accent-cyan: #00ebff;
$accent-orange: #ff9500;

$bg-color: #f3f3f3;

$alert-error: #ff7800;
$alert-succes: #008a05;
$alert-warning: #b200ff;

// gradients
$gradient-blue: -webkit-linear-gradient(0deg, $blue-universal, $accent-cyan);
$gradient-red: -webkit-linear-gradient(0deg, $red-universal, $accent-orange);

$gradient-claret: -webkit-linear-gradient(0deg, #c44377, #f068a1);

// fonts
$font-primary: "Futura Now Text", sans-serif;
$font-hedline: "Futura Now Headline", sans-serif;

// base bargin size
$margin-size: 8px;
$default-gap: 16px;
