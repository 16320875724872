.public {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .footer {
    margin-top: auto;
  }
}
.public-page {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    max-width: 648px;

    text-align: left;
  }

  &__heading {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    br {
      display: none;
    }
    @include respond(tablet) {
      font-size: 20px;
      br {
        display: block;
      }
    }
  }

  &__paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @include respond(tablet) {
      font-size: 18px;
    }
  }
}
