.my-points-add {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 290px;
  height: 290px;
  border-radius: 23.534px;
  background: #f3f3f3;
  @include box-shadow-on-color;
  // box-shadow:
  // -8px -8px 8px 0px rgba(255, 255, 255, 0.4),
  // 8px 8px 8px 0px rgba(163, 167, 178, 0.8);

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &::after {
      content: "";
      position: absolute;
      width: 198px;
      height: 198px;
      border-radius: 198px;
      box-shadow:
        -5.202px -5.202px 7px 0px #fff inset,
        5.202px 5.202px 9px 0px rgba(163, 167, 178, 0.55) inset;
    }

    &::before {
      content: "";
      position: absolute;
      width: 161px;
      height: 161px;
      border-radius: 161px;
      background: #f3f3f3;
      box-shadow:
        -5.202px -5.202px 7px 0px #fff,
        5.202px 5.202px 9px 0px rgba(163, 167, 178, 0.55);
    }
  }

  &__points {
    position: relative;
    z-index: 5;
    font-family: $font-hedline;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-variant-numeric: tabular-nums;
  }
}

.progress-ring {
  width: 188px;
  height: 188px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  mix-blend-mode: multiply;
}

.progress-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(270deg, #00ebff 2.3%, #0032ff 49.95%);
  position: absolute;
  top: 0;
  left: 0;
  mask-image: conic-gradient(#000 0deg, transparent 0deg);
}

.progress-ring::before {
  content: "";
  position: absolute;
  width: 174px;
  height: 174px;
  background: white;
  border-radius: 50%;
  z-index: 1;
  // border: 20px solid white;
}

.progress-value {
  position: relative;
  z-index: 2;

  font-family: $font-hedline;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  font-variant-numeric: tabular-nums;
  padding: 0;
  margin: 0 0 10px;
}
