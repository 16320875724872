.field-container {
  margin-bottom: calc($margin-size * 5);
  position: relative;
  @include respond(tablet) {
    width: fit-content;
  }
}

.input-control[type="text"],
.input-control[type="email"],
.input-control[type="number"],
.input-control[type="password"] {
  border-radius: 24px;
  outline: 0px solid #828282;
  height: 50px;
  background: transparent;
  padding-left: 28px;
  padding-right: 28px;
  border: 1px solid #828282;

  color: #5d5d5d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  position: relative;
  z-index: 100;

  @include respond(tablet) {
    width: 366px;
  }

  &:focus,
  &:focus-visible {
    border-color: $blue-universal;
  }

  &:disabled {
    border-color: #828282;
    opacity: 0.4;
    cursor: not-allowed;
  }
}

textarea.input-control {
  border-radius: 24px;
  outline: 0px solid #fff;
  min-height: 150px;
  background: transparent;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 11px;
  padding-bottom: 11px;
  border: 1px solid #fff;
  resize: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  position: relative;
  z-index: 100;
  &::placeholder {
    color: #fff;
    opacity: 1; /* Firefox */
  }

  @include respond(tablet) {
    width: 542px;
  }
}

.input-container {
  position: relative;
  label {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $bg-color;
    padding: 0 7px 0 8px;
    transition: all 0.08s ease-in-out;
    z-index: 1;
    cursor: text;
  }

  &.disabled {
    label {
      color: #929292;
      background-color: #f3f3f3;
      z-index: 101;
    }
  }

  .input-has-value,
  &:focus-within label {
    top: -2px;
    color: $blue-universal;
    z-index: 101;
  }

  .input-has-value + input {
    font-weight: 500;
    color: #000;
    border-color: $blue-universal;
  }

  &.error {
    .input-control {
      border-color: $accent-orange;
    }
    label {
      color: $accent-orange;
    }

    & + .input-helper {
      color: $accent-orange;
    }
  }

  .show-hide-password {
    position: absolute;
    top: 55%;
    display: inline-block;
    transform: translateY(50%);
    z-index: 104;
    path {
      color: #828282;
    }
  }

  .show-hide-password::after {
    content: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><style>path {color: grey}</style><path fill="currentColor" d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10m9.8 4a4 4 0 1 0 0-8a4 4 0 0 0 0 8m0-2a2 2 0 1 1 0-4a2 2 0 0 1 0 4"/></svg>');
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .hide::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><style>path {color: grey}</style><path fill="currentColor" d="m12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42z"/></svg>');
  }
}

.input-helper {
  color: #5d5d5d;
  font-size: 11px;
  line-height: 24px;
  margin-top: 0;
  left: 0;
  position: absolute;
}

.radio-group-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.radio-container {
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    text-align: left;
    font-size: 15px;
    font-weight: 300;

    a {
      color: #0032ff;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.radio-control {
  appearance: none;
  width: 18px;
  height: 18px;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  border-radius: 50%;
  border-color: #5d5d5d;
  border: 1px solid #5d5d5d;
  flex-shrink: 0;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg id='Warstwa_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cg id='Warstwa_1-2'%3E%3Cpath class='cls-1' d='M8,1c3.87,0,7,3.13,7,7s-3.13,7-7,7S1,11.87,1,8,4.13,1,8,1M8,0C3.59,0,0,3.59,0,8s3.59,8,8,8,8-3.59,8-8S12.41,0,8,0h0Z'/%3E%3C/g%3E%3C/svg%3E");
    background-color: $blue-universal;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 10px #dee5ff;
  }
  &:active {
    filter: brightness(100%);
  }
}

.radio-control {
  &--on-color {
    width: 14px;
    height: 14px;
    border: 1px transparent solid;
    outline: 1px solid white;
    outline-offset: 1px;

    &:checked {
      border: 1px transparent solid;
      outline: 1px solid white;
      background: white;
      // outline-offset: 1px;
    }

    &:focus {
      box-shadow: 0 0 0 12px rgba(229, 234, 251, 0.5);
      background: white;
    }
  }
}

.password-rules {
  text-align: left;
  margin-top: 8px;

  ul {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  li {
    color: #5d5d5d;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    font-weight: 400;
    list-style: none;
    align-items: flex-start;

    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkluZm8gSWNvbiBUZXN0Ij4KPHBhdGggaWQ9IlZlY3RvciAyNSIgZD0iTTcgNS44MDA3OFYxMC4yMDA4IiBzdHJva2U9IiM1RDVENUQiIHN0cm9rZS13aWR0aD0iMS42IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggaWQ9IlZlY3RvciAyNiIgZD0iTTcgMy40MDAzOUg3LjAwMDEiIHN0cm9rZT0iIzVENUQ1RCIgc3Ryb2tlLXdpZHRoPSIxLjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8Y2lyY2xlIGlkPSJFbGxpcHNlIDgwIiBjeD0iNi45OTg0NCIgY3k9IjcuMDAwMzkiIHI9IjYuMiIgc3Ryb2tlPSIjNUQ1RDVEIiBzdHJva2Utd2lkdGg9IjEuMiIvPgo8L2c+Cjwvc3ZnPgo=");
      display: inline-block;
      margin-right: 8px;
      width: 13px;
      height: 16px;
      margin-top: 1px;
    }
  }

  .password-rules-ok {
    color: $blue-universal;
    &::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTExLjE2NjkgMC4yNTIyNzJDMTEuMjQ1MiAwLjE3MjM5OSAxMS4zMzg2IDAuMTA4OTQ3IDExLjQ0MTggMC4wNjU2Mjk0QzExLjU0NDkgMC4wMjIzMTIgMTEuNjU1NiAwIDExLjc2NzQgMEMxMS44NzkyIDAgMTEuOTkgMC4wMjIzMTIgMTIuMDkzMSAwLjA2NTYyOTRDMTIuMTk2MiAwLjEwODk0NyAxMi4yODk2IDAuMTcyMzk5IDEyLjM2NzkgMC4yNTIyNzJDMTIuNjk1OSAwLjU4Mzc2NSAxMi43MDA1IDEuMTE5NDMgMTIuMzc5NCAxLjQ1NjY2TDUuNTk2OTQgOS40NzQ0M0M1LjUxOTk0IDkuNTU4OTkgNS40MjY0OSA5LjYyNjk1IDUuMzIyMzIgOS42NzQxNUM1LjIxODE0IDkuNzIxMzUgNS4xMDU0NCA5Ljc0NjgxIDQuOTkxMDkgOS43NDg5NkM0Ljg3Njc0IDkuNzUxMSA0Ljc2MzE1IDkuNzI5OSA0LjY1NzI4IDkuNjg2NjRDNC41NTE0MSA5LjY0MzM4IDQuNDU1NDggOS41Nzg5OCA0LjM3NTM1IDkuNDk3MzdMMC4yNDgzMjQgNS4zMTUyOEMwLjA4OTE1NzggNS4xNTI5NiAwIDQuOTM0NjkgMCA0LjcwNzM1QzAgNC40ODAwMiAwLjA4OTE1NzggNC4yNjE3NSAwLjI0ODMyNCA0LjA5OTQyQzAuMzI2NjA0IDQuMDE5NTUgMC40MjAwMzMgMy45NTYxIDAuNTIzMTQgMy45MTI3OEMwLjYyNjI0NyAzLjg2OTQ2IDAuNzM2OTU5IDMuODQ3MTUgMC44NDg3OTYgMy44NDcxNUMwLjk2MDYzMiAzLjg0NzE1IDEuMDcxMzUgMy44Njk0NiAxLjE3NDQ1IDMuOTEyNzhDMS4yNzc1NiAzLjk1NjEgMS4zNzA5OSA0LjAxOTU1IDEuNDQ5MjcgNC4wOTk0Mkw0Ljk1MDAyIDcuNjQ3MkwxMS4xNDQgMC4yNzc1MDdDMTEuMTUxMSAwLjI2ODY0MiAxMS4xNTg4IDAuMjYwMjE1IDExLjE2NjkgMC4yNTIyNzJaIiBmaWxsPSIjMDAzMkZGIi8+Cjwvc3ZnPgo=");
    }
  }
}
