.competition {
  padding-top: 24px;
}

.festivals {
  // padding-top: 64px;
  padding-bottom: 24px;
  @include respond(desktop) {
    padding-bottom: 0;
  }
}

.codes {
  background: url("static/img/home-codes-mobile.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  padding: 44px 0 25px;
  height: 628px;

  @include respond(tablet) {
    background: url("static/img/home-codes-tablet.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    padding: 94px 0 25px;
    height: 628px;

    // aspect-ratio: 2/1;
  }
  @include respond(desktop) {
    padding: 64px 0 39px;
    background: url("static/img/home-codes-desktop.jpg") no-repeat;
    // aspect-ratio: 1920/521;
    background-position: center;
    background-size: cover;

    height: 521px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
  }

  p {
    font-family: $font-hedline;
    font-weight: 400;
    font-size: 18px;
    max-width: 270px;

    @include respond(desktop) {
      max-width: 640px;
      font-size: 20px;
    }
  }

  &__container {
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
}
