.news-site--first {
  margin-top: 100px;
}

.news-site {
  .neu-block__paragraph {
    max-width: 778px;

    br {
      display: none;
    }
    @include respond(desktop) {
      br {
        display: block;
      }
    }
  }

  &__article {
    margin-top: 64px;
    margin-bottom: 160px;
  }

  &__article-content {
    padding-left: 10px;
    padding-right: 10px;
    @include respond(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 617px;

    h3,
    h4,
    h5,
    p {
      margin: 0;
      padding: 0;
      @include respond(mobile) {
        padding-left: 24px;
        padding-right: 24px;
      }

      strong,
      b {
        font-family: $font-hedline;
        font-weight: 900;
      }
    }

    p {
      font-weight: 400;
      font-size: 15px;
    }

    @include respond(tablet) {
    }

    img {
      border-radius: 25px;
      margin: 0;
      padding: 0;
      @include respond(tablet) {
        border-radius: 15px;
      }
    }
  }

  &__article-new-product-1 {
    margin-top: 0;
    padding-top: 136px;
    color: #fff;

    background: url("./static/img/news-art-1-bg.jpg") no-repeat;
    background-size: cover;

    @include respond(tablet) {
      background: url("./static/img/news-art-1-bg-tablet.jpg") no-repeat;
      background-position: center top;
      // background-size: cover;
    }

    @include respond(desktop) {
      background: url("./static/img/news-art-1-bg-desktop.jpg") no-repeat;
      background-position: center top;

      // background-size: cover;
    }

    @media only screen and (min-width: 1440px) {
      background: url("./static/img/news-art-1-bg-bigdesktop.jpg") no-repeat;
      background-position: center top;
    }
    @media only screen and (min-width: 1920px) {
      background-size: cover;
    }

    &.news-site__article {
      margin-bottom: 0;
      padding-bottom: 160px;
    }

    .news-site__article-content {
      align-items: center;
      max-width: 764px;
      margin-bottom: 104px;
      h2 {
        max-width: 764px;
        margin-bottom: 88px;
      }
      h4,
      p {
        max-width: 546px;
        text-align: center;
      }
    }
  }

  &__article-new-product-7 {
    margin-top: 0;
    // padding-top: 136px;
    color: #fff;

    background-image: url("./static/img/news-art-7-bg-mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;

    // @include respond(tablet) {
    //   background: url("./static/img/news-art-1-bg-tablet.jpg") no-repeat;
    //   background-position: center top;
    //   // background-size: cover;
    // }

    @include respond(desktop) {
      background-image: url("./static/img/news-art-7-bg-desktop.jpg");
      background-position: center top;

      // background-size: cover;
    }

    @media only screen and (min-width: 1920px) {
      background-size: cover;
    }

    &.news-site__article {
      margin-bottom: 0;
      padding-bottom: 160px;
    }

    .news-site__article-content {
      align-items: center;
      max-width: 764px;
      margin-bottom: 104px;
      h2 {
        max-width: 764px;
        margin-bottom: 44px;
      }
      h4,
      p {
        max-width: 605px;
        text-align: center;
      }
    }
  }

  &__quiz-start {
    margin-top: 0;
    padding-top: 136px;
    color: #fff;

    background-image: url("./static/img/quiz-start-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;

    // @include respond(tablet) {
    //   background: url("./static/img/news-art-1-bg-tablet.jpg") no-repeat;
    //   background-position: center top;
    //   // background-size: cover;
    // }

    // @include respond(desktop) {
    //   background-image: url("./static/img/quizzes-start-bg-desktop.jpg");
    //   background-position: center top;

    //   // background-size: cover;
    // }

    @media only screen and (min-width: 1920px) {
      background-size: cover;
    }

    &.news-site__article {
      margin-bottom: 0;
      padding-bottom: 160px;
    }

    .news-site__article-content {
      align-items: center;
      max-width: 764px;
      margin-bottom: 104px;
      h2 {
        max-width: 764px;
        margin-bottom: 44px;
      }
      h4,
      p {
        max-width: 605px;
        text-align: center;
      }
    }
  }

  &--art-7 {
    margin-bottom: 20px;
  }

  &--on-color {
    margin-bottom: -65px;
  }
}

.news-site-hero {
  min-height: 510px;
  position: relative;
  overflow: hidden;

  picture img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    min-width: fit-content;
    width: 100%;
    z-index: -1;
  }

  &--photo-align-center {
    picture img {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include respond(tablet) {
    min-height: 540px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    max-width: 252px;
    @include respond(tablet) {
      max-width: 352px;
    }
  }

  &__content {
    text-align: center;
    // margin-bottom: 64px;
    // @include respond(tablet) {
    //   margin-bottom: 0;
    // }
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;

    br {
      display: none;
      @include respond(tablet) {
        display: block;
      }
    }
  }
}

.news-site-hero-art-7 {
  @include respond(tablet) {
    min-height: 800px;
  }
}

.product-link {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 70px;
    margin-bottom: 64px;

    @include respond(tablet) {
      gap: 64px;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include respond(desktop) {
      width: 960px;
    }
  }

  max-width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond(tablet) {
    max-width: 424px;
  }

  > a {
    display: block;
    overflow: hidden;
    border-radius: 25px;
    margin-bottom: 16px;

    @include respond(tablet) {
      border-radius: 15px;
      margin-bottom: 27px;
    }

    @include box-shadow-on-color;

    &:hover {
      @include box-shadow-on-color-hover;
    }

    &:active {
      @include box-shadow-active;
    }
  }
}

.video-play {
  margin-bottom: 144px;
}

.news-site__contaier {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;
  justify-content: center;
  margin-bottom: 64px;

  .neu-block {
    max-width: 536px;
    margin-bottom: 0;
  }
}
