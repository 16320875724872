.vouchers {
  min-height: 1090px;
  padding: 0 16px;
  margin-bottom: 32px;

  @include respond(desktop) {
    min-height: 900px;
  }
  h1 {
    text-transform: uppercase;
  }

  &__nav {
    border-top: 1px solid rgba(0, 50, 255, 0.25);
    border-bottom: 1px solid rgba(0, 50, 255, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    list-style: none;
    padding: 0;
    max-width: 330px;
    width: 100%;
  }

  &__nav-item {
    position: relative;
    padding: 0;
    height: 75px;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      height: 3px;
      width: 0;
      position: absolute;
      transition: width 0.3s;
      background-color: $blue-universal;
      bottom: 0;
    }

    a {
      color: $blue-universal;
      font-size: 15px;
      font-family: $font-hedline;
      text-decoration: none;
      margin-top: -7px;
      padding: 0 12px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &.active {
      &::after {
        width: 100%;
      }
      a {
        font-weight: 800;
      }
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__container {
    padding: 40px 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 145px);
    gap: 24px;
    justify-content: center;
    transition: all 0.3s;

    @media only screen and (min-width: 340px) {
      max-width: 420px;
    }

    // margin: 0 auto;
    @include respond(tablet) {
      max-width: 600px;
    }
    @include respond(desktop) {
      max-width: 800px;
      gap: 32px;
      grid-template-columns: repeat(auto-fit, 232px);
    }
  }

  &__warunki {
    margin-top: 64px;
  }
}

.voucher {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @include respond(tablet) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 850px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 420px;

    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    padding-top: 32px;
    @include respond(tablet) {
      padding-top: 16px;
      padding-left: 32px;
    }
  }

  &__desc {
    margin-bottom: 32px;
    line-height: 22px;
    text-align: center;

    @include respond(tablet) {
      width: 100%;
      text-align: left;
    }

    strong {
      font-family: $font-hedline;
      font-weight: 700;
    }
  }

  &__info {
    width: 100%;
    font-weight: 700;
    margin-bottom: 32px;
    text-align: center;

    @include respond(tablet) {
      text-align: left;

      margin-bottom: 32px;
    }
  }
  &__info-2 {
    font-weight: 700;
    margin-bottom: 34px;
    line-height: 22px;
  }

  &__code-wrap {
    width: 100%;
  }
  &__code {
    // border: 3px dashed #003c71;
    border: 0;
    background: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23000' stroke-width='2' stroke-dasharray='15%2c 15%2c 12%2c 12' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
    width: 100%;
    padding: 23px 16px;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px;
    // text-transform: uppercase;
    margin-bottom: 24px;
    text-align: center;
    font-family: $font-hedline;
    color: #000;

    &:disabled {
    }
  }
  &__code-name {
    text-align: center;
    margin-bottom: 21px;
    font-weight: 800;
    font-size: 18px;
  }

  &__code-name-2 {
    width: 100%;

    margin-bottom: 32px;
  }

  &__date-expire {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    gap: 8px;
    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' viewBox='0 0 17 13' fill='none'%3E%3Cpath d='M3.79167 0.5C3.93533 0.5 4.0731 0.542801 4.17468 0.618988C4.27627 0.695175 4.33333 0.798506 4.33333 0.90625V1.3125H13V0.90625C13 0.798506 13.0571 0.695175 13.1587 0.618988C13.2602 0.542801 13.398 0.5 13.5417 0.5C13.6853 0.5 13.8231 0.542801 13.9247 0.618988C14.0263 0.695175 14.0833 0.798506 14.0833 0.90625V1.3125H15.1667C15.7413 1.3125 16.2924 1.4837 16.6987 1.78845C17.1051 2.0932 17.3333 2.50652 17.3333 2.9375V11.875C17.3333 12.306 17.1051 12.7193 16.6987 13.024C16.2924 13.3288 15.7413 13.5 15.1667 13.5H2.16667C1.59203 13.5 1.04093 13.3288 0.634602 13.024C0.228273 12.7193 0 12.306 0 11.875V2.9375C0 2.50652 0.228273 2.0932 0.634602 1.78845C1.04093 1.4837 1.59203 1.3125 2.16667 1.3125H3.25V0.90625C3.25 0.798506 3.30707 0.695175 3.40865 0.618988C3.51023 0.542801 3.64801 0.5 3.79167 0.5ZM1.08333 4.83333V11.875C1.08333 12.0905 1.19747 12.2971 1.40063 12.4495C1.6038 12.6019 1.87935 12.6875 2.16667 12.6875H15.1667C15.454 12.6875 15.7295 12.6019 15.9327 12.4495C16.1359 12.2971 16.25 12.0905 16.25 11.875V4.83333H1.08333Z' fill='%23000'/%3E%3C/svg%3E");
    }

    &--2 {
      width: 100%;

      @include respond(tablet) {
        width: 100%;
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
}

.voucher-hero {
  margin-top: 80px;
  min-height: 430px;
  position: relative;

  background-image: url("./static/img/vouchers-heading-m.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @include respond(tablet) {
    min-height: 460px;
    background-image: url("./static/img/vouchers-heading.jpg");
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    text-align: center;
    // margin-bottom: 64px;
    // @include respond(tablet) {
    //   margin-bottom: 0;
    // }
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;

    br {
      display: none;
      @include respond(tablet) {
        display: block;
      }
    }
  }

  &__subheading {
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    br {
      display: none;
      @include respond(tablet) {
        display: block;
      }
    }
  }
}
