.footer {
  padding-top: calc($margin-size * 6);
  background: $blue-universal;
  color: #fff;
  font-family: $font-hedline;

  @include respond(desktop) {
    padding-top: calc($margin-size * 5);
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include respond(desktop) {
      flex-direction: row;
      justify-content: space-between;
    }

    &--1 {
      display: flex;
      flex-direction: column;
      @include respond(desktop) {
        flex-direction: row;
        justify-content: left;
        align-items: center;
      }
    }
  }

  &__first-column {
    margin-bottom: 38px;
    @include respond(desktop) {
      flex-basis: 30%;
    }

    &--1 {
      // align-self: flex-start;
      @include respond(desktop) {
        align-self: flex-start;
      }
    }
  }

  &__columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond(desktop) {
      flex-basis: 65%;
    }
  }

  &__procucts,
  &__menu,
  &__user-menu,
  &__contact {
    margin-bottom: 30px;
    flex-basis: 40%;
    padding-left: calc(40px - 16px);

    @include respond(mobile) {
      flex-basis: 25%;
    }
    @include respond(desktop) {
      flex-basis: auto;
    }
  }

  @include respondMax(mobile) {
    &__contact {
      flex-basis: 47%;
      margin-bottom: 30px;
    }

    &__procucts {
      flex-basis: 60%;
    }

    &__user-menu {
      flex-basis: 53%;
      margin-bottom: 30px;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-hedline;

    @include respond(desktop) {
      font-size: 16px;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &__paragraph {
    font-size: 14px;
    margin-bottom: calc($margin-size * 2);
    font-family: $font-hedline;
    font-weight: 400;

    @include respond(desktop) {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 11px;
      @include respond(desktop) {
        margin-bottom: $margin-size;
      }
    }
  }

  &__logo {
    margin-bottom: calc($margin-size * 3);
    text-align: center;
    @include respond(desktop) {
      text-align: left;
      margin-bottom: calc($margin-size * 10);
    }
    img {
      width: 66px;
      display: inline-block;
    }

    &--2 {
      margin-bottom: calc($margin-size * 8);

      img {
        width: 58px;
      }
    }
  }

  &__warning {
    border-radius: 15px;
    background: #002bb5;
    padding: 19px 22px;
    margin: 0 3px;

    @include respond(tablet) {
      max-width: 332px;
      margin: 0 auto;
    }
    @include respond(desktop) {
      margin: 0 3px;
    }
  }

  &__label {
    margin-bottom: 11px;
    font-size: 14px;
    @include respond(tablet) {
      font-size: 16px;
      margin-bottom: $margin-size;
    }
  }

  &__legal-container {
    border-top: 1px solid #4461be;

    .container {
      padding-left: 40px;
      padding-top: 19px;

      display: flex;
      flex-direction: column-reverse;

      @include respond(tablet) {
        padding-left: 40px;
        padding-right: 40px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      @include respond(desktop) {
        padding: 0 calc($margin-size * 2);
      }
    }
  }

  &__legal-menu {
    @include respond(tablet) {
      display: flex;
      gap: 24px;
    }
    @include respond(desktop) {
      display: flex;
      gap: 64px;
    }

    li {
      margin-bottom: $margin-size;

      a {
        padding: 0;
      }
    }
  }

  &__legal {
    margin-top: 28px;
    margin-bottom: 28px;
    font-size: 14px;
    font-family: $font-hedline;
    font-weight: 400;

    @include respond(desktop) {
      font-size: 16px;
    }
  }
}

.footer-contact {
  margin: 0 auto;
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 90px;

  @include respond(tablet) {
    gap: 90px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 46px;
  }

  &__icon {
    margin-bottom: 8px;
    height: 53px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item-headline {
    margin: 0;
    font-size: 16px;
  }

  &__item-paragraph {
    margin: 0;
    font-size: 16px;
    font-family: $font-hedline;
    font-weight: 400;
    text-wrap: nowrap;

    a {
      color: #fff;
      text-decoration: none;
      text-wrap: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
