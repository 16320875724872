.registration-page {
  display: flex;
  flex-direction: column;

  @include respond(desktop) {
    flex-direction: row;
    min-height: calc(100vh - 50px);
  }

  &--2 {
    @include respond(desktop) {
      min-height: unset;
    }
  }

  &__logo {
    margin: 0 auto 36px;

    img {
      margin: 0 auto;
    }
  }

  h2 {
    font-size: 25px;
  }
  h4 {
    font-size: 16px;
    max-width: 364px;
  }
  p {
    max-width: 382px;
  }
  form {
    width: 100%;
  }

  &__btn {
    margin-bottom: 110px;
  }

  &__bottom-links {
    a {
      font-size: 12px;
    }
  }

  &__voucher {
    img {
      border-radius: 25px;
      max-width: 328px;
      width: 100%;

      @include respond(tablet) {
        max-width: 648px;
        width: 100%;
      }
    }
  }

  &__voucher-text {
    br {
      display: none;
      @include respond(tablet) {
        display: block;
      }
    }
  }
}

.registration-around {
  display: flex;
  justify-content: space-around;
}
.registration-center {
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.registration-container {
  padding-top: 34px;
  padding-bottom: 34px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registration-one-column {
  flex-direction: column;

  h4 {
    max-width: none;
  }
  p {
    max-width: none;
  }
}

.registration-more-info {
  text-align: left;
  max-width: 647px;
  &__paragraph {
    font-family: $font-primary;
    font-weight: 500;
    align-self: flex-start;
    max-width: 647px;
  }
  p {
    max-width: none;
  }
  ol {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 16px;
    margin-bottom: 40px;
  }

  li {
    font-size: 15px;
    font-weight: 300;
  }

  ul {
    list-style: disc;
    padding-left: 16px;

    li::marker {
      font-size: 8px;
    }
  }

  &__contact {
    h4 {
      font-size: 14px;
    }
    p {
      font-family: $font-hedline;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.back-button {
  align-self: flex-start;
  margin-bottom: 8px;
  margin-top: 8px;
}

.register-form {
  max-width: 366px;
  @include respond(tablet) {
    max-width: none;
  }

  &__container {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;

    .field-container {
      width: 276px;
      @include respond(tablet) {
        width: 366px;
      }
    }
  }

  &__container form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond(tablet) {
    }
  }

  .radio-container {
    input {
      margin-top: 15px;
    }
  }

  .required-fields-text {
    text-align: right;
    color: $blue-universal;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    max-width: 366px;
  }
  .info-rules {
    max-width: 761px;
    font-weight: 300;
    font-size: 15px;
    text-align: left;

    &--2 {
      max-width: 464px;
    }

    a {
      font-style: normal;
      font-weight: 500;
      color: $blue-universal;
    }
  }
}

.agreement-block {
  border-radius: 15px;
  border: 1px solid rgba(0, 50, 255, 0.4);
  padding: 24px 32px 45px;
  text-align: left;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 40px;
  }

  @include respond(tablet) {
    width: 100%;
    max-width: 761px;
  }

  p {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .radio-container {
    input {
      margin-top: inherit;
    }
  }
}

.reg-text {
  a {
    color: #0032ff;
    font-weight: 500;
    text-decoration: none;
  }
}

.after-register-contact {
  display: flex;
  justify-content: center;
  gap: 32px;
  p {
    font-family: $font-hedline;
    font-size: 18px;
    font-weight: 400;

    a {
      color: #000;
      text-decoration: none;
    }

    strong {
      font-weight: 800;
    }
  }
}

.after-register-text {
  a {
    color: $blue-universal;
    text-decoration: none;
  }
}

.pass-info {
  font-size: 14px;
  margin-bottom: 16px;

  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: -30px;

  @include respond(tablet) {
    font-size: 16px;
  }
}
