.mb-0 {
  margin-bottom: 0 !important;
}
.mb-xs {
  margin-bottom: $margin-size !important;
}

.mb-s {
  margin-bottom: calc($margin-size * 2) !important;
}

.mb-md {
  margin-bottom: calc($margin-size * 3) !important;
}

.mb-md2 {
  margin-bottom: calc($margin-size * 4) !important;
}

.mb-lg {
  margin-bottom: calc($margin-size * 6) !important;
}

.mb-lg2 {
  margin-bottom: calc($margin-size * 5) !important;
}

.mb-xl {
  margin-bottom: calc($margin-size * 8) !important;
}
.mb-xxl {
  margin-bottom: calc($margin-size * 10) !important;
}
.mr-md {
  margin-right: calc($margin-size * 3) !important;
}

.mt-from-navbar {
  margin-top: 96px;
}

.mt-xs {
  margin-top: $margin-size !important;
}

.mt-xl {
  margin-top: calc($margin-size * 8) !important;
}

.p-0 {
  padding: 0 !important;
}

.p-xl {
  padding: calc($margin-size * 8) !important;
}

.pt-md {
  padding-top: calc($margin-size * 3) !important;
}

.px-sm {
  padding-left: $margin-size !important;
  padding-right: $margin-size !important;
}
.px-md {
  padding-left: calc($margin-size * 3) !important;
  padding-right: calc($margin-size * 3) !important;
}

.py-xl {
  padding-top: calc($margin-size * 8) !important;
  padding-bottom: calc($margin-size * 8) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.text-xs {
  font-size: 10px !important;
}

.text-s {
  font-size: 12px !important;
}

.text-md {
  font-size: 15px !important;
}

.text-md2 {
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-align-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-regular {
  font-weight: 400 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.text-color-light {
  color: #f3f3f3;
}

.text-color-warning {
  color: #e00;
}

.center-mobile {
  @include respondMax(tablet) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.display-on-mobile {
  display: block;
  @include respond(desktop) {
    display: none !important;
  }
}

.display-on-desktop {
  display: none !important;
  @include respond(desktop) {
    display: block !important;
  }
}

.align-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.container-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.no-wrap {
  text-wrap: nowrap;
}

.text-gradient-blue.align-center {
  width: max-content;
}

.justify-center {
  justify-content: center;
}

.fill-content-100 {
  width: 100% !important;
}

.text-color-blue {
  color: $blue-universal;
}
