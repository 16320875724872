.products-slider {
  --swiper-navigation-size: 25px;
  --swiper-navigation-top-offset: 410px;

  @include respond(tablet) {
    --swiper-navigation-top-offset: 530px;
  }

  @include respond(big-desktop) {
    --swiper-navigation-sides-offset: 100px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
  }

  &--black {
    .swiper-button-next,
    .swiper-button-prev {
      color: #000;
    }
  }
}

.product {
  &__slide {
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 1439px;
    @include respond(tablet) {
      min-height: 1000px;
      padding-top: 267px;
    }
  }

  &__bg {
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;

    &--bg1 {
      background-image: url("static/img/products/bg1_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg1.jpg");
      }
    }
    &--bg2 {
      background-image: url("static/img/products/bg2_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg2.jpg");
      }
    }
    &--bg3 {
      background-image: url("static/img/products/bg3_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg3.jpg");
      }
    }
    &--bg4 {
      // color: #000;
      --product-color: #000;
      color: var(--product-color);
      background-image: url("static/img/products/bg4_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg4.jpg");
      }
    }
    &--bg5 {
      // color: #000;
      --product-color: #000;
      color: var(--product-color);
      background-image: url("static/img/products/bg5_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg5.jpg");
      }

      .swiper-button-next,
      .swiper-button-prev {
        color: #000;
      }
    }
    &--bg6 {
      background-image: url("static/img/products/bg6_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg6.jpg");
      }
    }
    &--bg7 {
      background-image: url("static/img/products/bg7_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg7.jpg");
      }
    }
    &--bg8 {
      background-image: url("static/img/products/bg8_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg8.jpg");
      }
    }
    &--bg9 {
      background-image: url("static/img/products/bg9_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg9.jpg");
      }
    }
    &--bg10 {
      background-image: url("static/img/products/bg10_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg10.jpg");
      }
    }

    &--bg11 {
      background-image: url("static/img/products/bg11_mobile.jpg");
      @include respond(tablet) {
        background-image: url("static/img/products/bg11.jpg");
      }
    }
  }

  &__container {
    max-width: 1096px;
    margin: 0 auto;

    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 32px;

    @include respond(tablet) {
      padding: 0 16px;
      grid-template-rows: auto 1fr;
      grid-template-columns: repeat(10, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 0px;
    }
  }

  &__packshoot-container {
    grid-area: 2 / 1 / 3 / 5;

    justify-self: center;

    @include respond(tablet) {
      grid-area: 1 / 1 / 4 / 5;
    }
  }

  &__packshoot {
    max-width: 210px;
    @include respond(tablet) {
      // padding: 16px;
      max-width: 309px;
    }
    @include respond(desktop) {
      // padding: 16px 0;
    }
  }
  &__name-container {
    grid-area: 1 / 1 / 2 / 5;
    justify-self: center;
    text-align: center;
    @include respond(tablet) {
      grid-area: 1 / 5 / 2 / 11;
      justify-self: self-start;
      text-align: left;
    }
  }

  &__line-name {
    font-family: $font-hedline;
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 16px;
    @include respond(tablet) {
      font-size: 18px;
    }
  }
  &__name {
    margin-bottom: 0;
    @include respond(tablet) {
      margin-bottom: 24px;
    }
  }

  &__descritpion-container {
    grid-area: 3 / 1 / 4 / 5;
    justify-self: center;
    text-align: center;

    max-width: 322px;

    @include respond(tablet) {
      grid-area: 2 / 5 / 4 / 11;

      justify-self: self-start;
      text-align: left;
      max-width: none;
    }
  }

  &__heading {
    margin-bottom: 8px;
  }
  &__description,
  &__format {
    margin-bottom: 32px;
    max-width: 483px;
    font-weight: 400;

    @include respond(tablet) {
      margin-bottom: 8px;
    }
  }

  &__description {
    margin-bottom: 56px;
  }
}

.features {
  padding: 0 16px;
  margin-bottom: 32px;
  @include respond(tablet) {
    padding: 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    @include respond(tablet) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: $font-hedline;
    font-size: 16px;
    margin-bottom: 32px;
    font-weight: 400;

    @include respondMax(mobile) {
      &::after {
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMSAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjUgMTdMMC45NzM3MTkgMC41MDAwMDJMMjAuMDI2MyAwLjVMMTAuNSAxN1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
      }
    }
  }

  &--black {
    .features__title {
      @include respondMax(mobile) {
        &::after {
          content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMSAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlBvbHlnb24gMSIgZD0iTTEwLjUgMTdMMC45NzM3MTkgMC41MDAwMDJMMjAuMDI2MyAwLjVMMTAuNSAxN1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
        }
      }
    }
  }

  &__item {
    max-width: 292px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    @include respond(tablet) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
      min-width: 151px;
      margin-left: -11px;
    }
  }
  &__icon {
    img {
      max-width: 108px;
    }
  }
  &__name {
    text-align: left;
    margin-bottom: 0;
    font-weight: 400;

    @include respond(tablet) {
      text-align: center;
    }
  }
}

.products-nav {
  display: none;
  @include respond(tablet) {
    padding: 0 16px;
    position: absolute;
    display: block;
    top: 147px;
    z-index: 5;
    width: 100%;
    left: 50%;
    max-width: 877px;
    transform: translateX(-50%);
    color: var(--product-color, #fff);
  }

  ul {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding: 0;

    @include respond(desktop) {
      gap: 80px;
    }
  }

  li {
    padding: 0;
    list-style: none;
  }

  &--black {
    --product-color: #000;

    a {
      color: var(--product-color, #fff);
    }
  }

  a {
    color: var(--product-color);
    font-family: $font-hedline;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    text-wrap: nowrap;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50px;
      left: 0;
      background: var(--product-color, #fff);
      width: 0%;
      height: 3px;
      transition: width 0.15s ease-in-out;
    }

    &.active {
      font-weight: 900;
      &::after {
        width: 100%;
      }
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}
