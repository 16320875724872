.slider-wrapper {
  display: flex;
  grid-gap: 14px;
  max-width: 768px;
  cursor: grab;
  margin-top: 30px;
  min-width: 100%;

  @include respond(desktop) {
    margin-top: 6px;
    max-width: 100%;
    grid-gap: 38px;
    align-self: flex-start;
    cursor: default;
  }
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x;
  scroll-behavior: smooth;
  scrollbar-width: none; /* for firefox */

  @include respond(desktop) {
    // scroll-snap-type: x;
    // scroll-behavior: unset;
  }
}

.wrapper::-webkit-scrollbar {
  // /* for chrome */
  display: none;
}

.slider {
  min-width: 258px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scroll-snap-align: center;
  user-select: none;
  padding-top: 10px;

  @include respond(desktop) {
    min-width: 550px;
    // scroll-snap-align: unset;
  }

  &:first-child {
    margin-left: 19px;
    // @include respond(desktop) {
    //   margin-left: 16px;
    // }
    @include respond(big-desktop) {
      margin-left: calc((100vw - 1160px) / 2);
    }
  }
  &:last-child {
    margin-right: 19px;
    // @include respond(desktop) {
    //   margin-right: 16px;
    // }
    @include respond(big-desktop) {
      margin-right: calc((100vw - 1160px) / 2);
    }
  }

  &__link {
    display: block;
    // position: relative;
    // z-index: -1;
    // user-select: none;

    overflow: hidden;
    border-radius: 25px;
    margin-bottom: 24px;
    background-color: transparent;
    @include respond(tablet) {
      border-radius: 15px;
    }
    @include box-shadow;
    &:hover {
      @include box-shadow-hover;
    }

    &:active {
      @include box-shadow-active;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: -1;
    border-radius: 15px;

    // user-select: none;
  }

  &__paragraph {
    font-family: $font-hedline;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    @include respond(tablet) {
      font-size: 18px;
    }
  }

  &__nav {
    margin-top: 24px;
    display: flex;
    gap: 12px;
    @include respond(desktop) {
      max-width: 1140px;
      width: 100%;
    }
  }

  &__scrollbar {
    width: calc(100% - 34px);
    height: 3px;
    background-color: #dbdbdb;
    max-width: 1140px;
    overflow-x: hidden;
    position: relative;
    margin: 0 auto;
    @include respond(desktop) {
      margin: 40px auto 0;
    }
    span {
      position: absolute;
      left: 0;
      height: 3px;
      // width: 200px;
      background: $gradient-blue;
    }
  }
}
