@import "_mixins/font-face";

//Furura Now Text light
//Furura Now Text Regular
//Furura Now Text condenset
@include font-face("Futura Now Text", "./static/fonts/futura-now-text-light-webfont", 300);
@include font-face("Futura Now Text", "./static/fonts/futura-now-text-rg-webfont", 400);
@include font-face("Futura Now Text", "./static/fonts/futura-now-text-medium-webfont", 500);
@include font-face("Futura Now Text Condensed", "./static/fonts/futura-now-text-condensed-webfont", normal);

//Furura Now headline light
//Furura Now headline bold
//Furura Now headline extra bold
//Furura Now headline Black
@include font-face("Futura Now Headline", "./static/fonts/futuranowheadlinelight-webfont", 300);
@include font-face("Futura Now Headline", "./static/fonts/futuranowheadline-webfont", 400);
@include font-face("Futura Now Headline", "./static/fonts/futuranowheadlinebold-webfont", 700);
@include font-face("Futura Now Headline", "./static/fonts/futuranowheadlineextrabold-webfont", 800);
@include font-face("Futura Now Headline", "./static/fonts/futuranowheadlineblack-webfont", 900);
