p {
  font-family: $font-primary;
  font-size: 15px;
  line-height: normal;
  margin-bottom: calc($margin-size * 3);
  font-weight: 300;

  strong {
    font-weight: 500;
  }
}
