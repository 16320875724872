.add-code {
  h1 {
    text-transform: uppercase;
  }
  .field-container {
    margin-bottom: 60px;
  }

  &__info {
    border-radius: 15px;
    background: rgba(0, 50, 255, 0.1);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 19px 32px;
    max-width: 445px;
    width: 100%;
    margin-top: 64px;
  }
}

.add-code-article {
  margin-bottom: 64px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    * {
      margin: 0;
    }

    img {
      border-radius: 25px;
    }
  }
}

.contact-section {
  margin-bottom: 104px;

  p strong {
    font-weight: 800;
  }
}
