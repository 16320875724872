.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;

  &.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  &-bg {
    position: absolute;
    background: rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 100%;
  }
  &-container {
    border-radius: 15px;
    background: #f3f3f3;
    position: relative;
    padding: 71px 8px 48px;
    width: 100%;
    @include respond(tablet) {
      padding: 71px 40px 48px;
      width: auto;
    }
    @include respond(desktop) {
      padding-left: 140px;
      padding-right: 140px;
    }
    h2 {
      font-size: 25px;
    }

    p strong {
      font-weight: 800;
    }
  }

  &--dialog {
    .modal-container {
      padding: 79px 48px 57px;

      @include respond(tablet) {
        // padding: 79px 48px 101px;
        max-width: 568px;
        width: 100%;
      }
      // @include respond(desktop) {
      //   max-width: 568px;
      // }
    }
  }

  &-close {
    position: absolute;
    right: 32px;
    top: 24px;
    cursor: pointer;
  }

  &__dialog-btn-container {
    display: flex;
    gap: 16px;

    justify-content: center;
    margin-top: 24px;
    @include respond(tablet) {
      justify-content: center;
      margin-top: 40px;
    }
  }

  &--gradient-1 .modal-container {
    background:
      radial-gradient(
          circle at 99.87% 99.86%,
          rgba(195, 101, 155, 0.73) 0%,
          rgba(143, 93, 150, 0.07300000000000001) 44.16%
        )
        50% 50% no-repeat,
      radial-gradient(circle at 38.37% 1.73%, rgba(195, 101, 155, 0.43) 0%, rgba(143, 93, 150, 0.043) 23.88%) 50% 50%
        no-repeat,
      linear-gradient(
          62.37deg,
          rgba(255, 0, 0, 1) -50%,
          rgba(255, 0, 51, 1) 7.04%,
          rgba(217, 39, 84, 1) 21.51%,
          rgba(198, 33, 105, 1) 40.79%,
          rgba(144, 25, 158, 1) 59.94%,
          rgba(49, 43, 229, 1) 94.81%
        )
        50% 50%;

    h2,
    h4 {
      color: white;
    }
  }

  &--bg-1 .modal-container {
    max-width: 568px;
    background-image: url(./static/img/modal-bg-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    h2,
    h4 {
      color: white;
    }
  }

  &--bg-2 .modal-container {
    max-width: 708px;
    background-image: url(./static/img/modal-bg-2-m.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    @include respond(desktop) {
      background-image: url(./static/img/modal-bg-2.jpg);
    }

    h2,
    h4 {
      color: white;
    }
  }

  &--bg-3 .modal-container {
    max-width: 708px;
    background-image: url(./static/img/modal-bg-3-m.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    @include respond(desktop) {
      background-image: url(./static/img/modal-bg-3.jpg);
      padding-left: 120px;
      padding-right: 120px;
    }

    p,
    h2,
    h4 {
      color: white;
    }
  }
}
