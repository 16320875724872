@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;
		src:
			url("#{$file-path}.woff2") format("woff2"),
			url("#{$file-path}.woff") format("woff");
	}
}
