.contact {
  margin-bottom: 128px;
  h1 {
    text-transform: uppercase;
  }

  // &__paragraph {
  // }

  &__container {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    @include respond(tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 597px;
      margin: 77px auto;
    }
  }
  &__icon {
    margin-bottom: 8px;
    height: 53px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__item {
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
  }

  &__item-headline {
    margin: 0;
    font-size: 25px;
    @include respond(tablet) {
      font-size: 20px;
    }
  }

  &__item-paragraph {
    margin: 0;
    font-size: 25px;
    font-family: $font-hedline;
    font-weight: 400;
    @include respond(tablet) {
      font-size: 20px;
    }

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
