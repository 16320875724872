@mixin box-shadow() {
  box-shadow:
    8px 8px 8px rgba(163, 167, 178, 1),
    -8px -8px 8px #ffffff;
  transition: box-shadow 0.07s linear;
}

@mixin box-shadow-hover() {
  box-shadow:
    3px 3px 5px #a3a7b2,
    -5px -5px 5px #ffffff;
  transition: box-shadow 0.07s linear;
}

@mixin box-shadow-on-color() {
  box-shadow:
    8px 8px 8px rgba(0, 0, 0, 0.3),
    -8px -8px 8px rgba(255, 255, 255, 0.4);
  transition: box-shadow 0.07s linear;
}

@mixin box-shadow-on-color-hover() {
  box-shadow:
    3px 3px 5px rgba(0, 0, 0, 0.3),
    -5px -5px 5px rgba(255, 255, 255, 0.4);
  transition: box-shadow 0.07s linear;
}

@mixin box-shadow-active() {
  box-shadow:
    inset 5px 5px 5px #a3a7b2,
    inset -5px -5px 5px #ffffff;
}
