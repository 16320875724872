@mixin respond($breakpoint) {
    @if $breakpoint == mobile {
        @media only screen and (min-width: $mobile) {
            @content;
        }
    }

    @if $breakpoint == tablet {
        @media only screen and (min-width: $tablet) {
            @content;
        }
    }

    @if $breakpoint == desktop {
        @media only screen and (min-width: $desktop) {
            @content;
        }
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: $big-desktop) {
            @content;
        }
    }
}

@mixin respondMax($breakpoint) {
    @if $breakpoint == mobile-small {
        @media only screen and (max-width: $mobile-small) {
            @content;
        }
    }
    @if $breakpoint == mobile {
        @media only screen and (max-width: $mobile-max) {
            @content;
        }
    }

    @if $breakpoint == tablet {
        @media only screen and (max-width: $tablet-max) {
            @content;
        }
    }

    @if $breakpoint == desktop {
        @media only screen and (max-width: $desktop-max) {
            @content;
        }
    }
}

@mixin respondMobileHeight() {
    @media (max-height: 741px) and (orientation: portrait) {
        @content;
    }
}
