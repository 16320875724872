.my-points {
  margin-bottom: 16px;
  width: 290px;
  height: 290px;
  border-radius: 23.534px;
  background: #f3f3f3;
  box-shadow:
    -7.531px -7.531px 7.531px 0px #fff,
    7.531px 7.531px 7.531px 0px rgba(163, 167, 178, 0.7);

  &__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      width: 198px;
      height: 198px;
      border-radius: 198px;
      box-shadow:
        -5.202px -5.202px 7px 0px #fff inset,
        5.202px 5.202px 9px 0px rgba(163, 167, 178, 0.55) inset;
    }

    &::before {
      content: "";
      position: absolute;
      width: 161px;
      height: 161px;
      border-radius: 161px;
      background: #f3f3f3;
      box-shadow:
        -5.202px -5.202px 7px 0px #fff,
        5.202px 5.202px 9px 0px rgba(163, 167, 178, 0.55);
    }
  }

  &__points {
    position: relative;
    z-index: 5;
    font-family: $font-hedline;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-variant-numeric: tabular-nums;
  }
}
