.info-box {
  border-radius: 5px;
  background: #dfe6ff;

  display: inline-flex;
  min-height: 66px;
  max-width: 420px;
  padding: 12px 18px 12px 30px;
  min-width: 100%;

  @include respond(mobile) {
    min-width: 420px;
  }

  &__content {
    margin: 0;
  }

  align-items: center;
  gap: 19px;

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 26 26' fill='none'%3E%3Ccircle cx='13.001' cy='13.001' r='11.3667' fill='%230032FF' stroke='%230032FF' stroke-width='2.2'/%3E%3Cpath d='M13 10.7969V18.8635' stroke='white' stroke-width='2.93333' stroke-linecap='round'/%3E%3Cpath d='M13 6.39844H13.0001' stroke='white' stroke-width='2.93333' stroke-linecap='round'/%3E%3C/svg%3E");
    width: 22px;
    height: 22px;
  }
}
