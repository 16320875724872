.content {
  &__block {
    h3 {
      @include respond(tablet) {
        margin-bottom: 64px;
      }
    }

    &__subheader {
      margin-bottom: 16px;
      @include respond(tablet) {
        margin-bottom: 24px !important;
      }
    }
  }
}
