:root {
  color-scheme: only light;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  // line-height: calc(1em + 0.5rem);
}
html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
  -webkit-tap-highlight-color: transparent;
  // -webkit-appearance: none;
}
a {
  -webkit-tap-highlight-color: transparent;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  line-height: 1em;
}
#root,
#__next {
  isolation: isolate;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px $bg-color inset;
}

#next-section {
  margin-top: -64px;
  height: 64px;
}

.scroll-down {
  position: relative;
}
