.my-account {
  margin-bottom: 150px;
  padding: 0 16px;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include respond(tablet) {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      max-width: 1096px;
      margin: 0 auto 48px;
    }
  }

  &__container-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__content {
    max-width: 291px;
  }

  &__content-2 {
    max-width: 291px;
    @include respond(tablet) {
      max-width: none;
    }
  }

  &__link {
    margin: 16px auto;
    display: block;
    border: 0;
    padding: 0;
    border-radius: 15px;
    max-width: 290px;
    cursor: pointer;

    @include respond(tablet) {
      max-width: 1096px;
    }

    @include box-shadow;

    &:hover {
      @include box-shadow-hover;
    }

    &:active {
      @include box-shadow-active;
    }

    img {
      border-radius: 15px;
    }
  }

  &__add-points {
    max-width: 290px;
    margin-bottom: 16px;

    @include respond(tablet) {
      margin-top: 64px;
      margin-bottom: 0;
    }
    img {
      max-width: 290px;
      width: 100%;
    }
  }

  &__vouchers,
  &__my-profile,
  &__my-preferences {
    margin-bottom: 12px;
    @include respond(tablet) {
      margin-bottom: 12px;
    }
  }
}
