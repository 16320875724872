.festivals-hero {
  min-height: 469px;
  position: relative;
  overflow: hidden;
  padding: 0 16px;

  picture img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    min-width: fit-content;
    width: 100%;
    z-index: -1;
  }

  &--photo-align-center {
    picture img {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include respond(tablet) {
    min-height: 800px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    text-align: center;
    margin-top: 80px;
    border-radius: 18px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(3.5px);
    padding: 36px 16px 64px;

    @include respond(tablet) {
      padding: 50px 20px 70px;
      max-width: 565px;
      margin-bottom: 0;
    }
  }

  &__heading {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0;
    br {
      display: block;
      @include respond(desktop) {
        display: none;
      }
    }
  }
}

.festivals-content {
  padding: 24px 0 44px;
  text-align: center;

  @include respond(tablet) {
    padding: 64px 16px;
  }

  &__heading {
    max-width: 472px;
    @include respond(desktop) {
      max-width: 572px;
    }
  }

  &__subheading {
    max-width: 483px;

    @include respond(desktop) {
      max-width: 683px;
    }
  }
}

.festivals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.festivals-juwenalia {
  padding-bottom: 40px;

  @include respond(desktop) {
    padding-bottom: 80px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(tablet) {
      flex-direction: row;
      justify-content: center;
      max-width: 1083px;
      margin: 0 auto;
    }
  }

  &__photo-container {
    max-width: 424px;
    margin-bottom: 24px;

    @include respond(desktop) {
      margin-bottom: 0;
      max-width: 538px;
    }
  }

  &__photo {
    border-radius: 15px;
  }

  &__content {
    text-align: center;
    max-width: 538px;
    @include respond(tablet) {
      text-align: left;
      margin-left: 48px;
    }
    @include respond(desktop) {
      margin-left: 108px;
      margin-bottom: 40px;
    }
  }

  &__paragraph {
    text-align: left;
  }
}

.festivals-list {
  margin-bottom: 90px;
  @include respond(desktop) {
    margin-bottom: 104px;
  }
  &__container {
    @include respond(desktop) {
      max-width: 1123px;
      margin: 0 auto;
    }
  }
  &__accordion-container {
    position: relative;
  }
  &__accordion {
    cursor: pointer;
    width: 100%;
    background: none;
    padding: 24px 0 0;
    border: none;
    text-align: left;
    outline: none;
    font-family: $font-hedline;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    margin-bottom: 40px;

    &:not(:first-child) {
      border-top: 1px solid #a6a6a6;
    }

    @include respond(tablet) {
      width: 35%;
      font-weight: 700;

      max-width: 420px;
    }

    @include respond(desktop) {
      font-size: 35px;

      width: 45%;
      // padding: 24px 0 35px;
    }

    &::after {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlZlY3RvciAyNyIgZD0iTTIuMDMxMjUgMS43NjQ2NUwxNS42MTk1IDE0LjExNzZMMjcuOTcyNCAxLjc2NDY1IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIuNDcwNTkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
      float: right;
      margin-left: 5px;
      transition: transform 0.2s;
    }

    &.active {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__panel {
    // padding: 0 18px;
    max-height: 0px;
    // max-height: fit-content;
    overflow: hidden;
    transition: all 0.2s ease-out;
    @include respond(tablet) {
      width: 35%;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 40px;
    }

    img {
      border-radius: 33px;
      max-width: 290px;
      margin: 0 auto;
      margin-bottom: 40px;
      @include respond(tablet) {
        max-width: 55%;
        position: absolute;
        right: 0;
        top: 0;
        // transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;
      }

      @include respond(desktop) {
        width: 52%;
        max-width: 584px;
      }
    }
  }

  &__accordion {
    &.active + img {
      opacity: 1;
    }
  }
}

.festivals-list__accordion.active + .festivals-list__panel img {
  opacity: 1;
}

.festivals-gallery {
  margin-bottom: 167px;
  @include respond(desktop) {
    margin-bottom: 128px;
  }
  .slider {
    &__link {
      box-shadow: none;
    }
  }

  br {
    display: none;
    @include respond(desktop) {
      display: block;
    }
  }
}
